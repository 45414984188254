import Layout from '../components/Layout'
import React from 'react'
import Section from '../components/Section'
import Seo from '../components/Seo'
import fr from '../locales/fr'

const classes = /** class={ */ {
  description: 'text-2xl italic font-light text-center',
} /** } */

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title={fr.notFound} noIndex />
      <Section title={fr.notFoundTitle} onlyOne>
        <p className={classes.description}>{fr.notFoundDescription}</p>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
