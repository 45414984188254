import React from 'react'

interface SectionProps {
  id?: string
  title: string
  onlyOne?: boolean
}
const classes = /** class={ */ {
  section: 'container py-16 md:py-24 lg:py-32',
  title: 'font-display text-center text-2xl md:text-3xl lg:text-6xl mb-12 md:mb-20 lg:mb-24',
} /** } */

const Section: React.FunctionComponent<SectionProps> = ({ title, onlyOne, id, children }) => (
  <section className={classes.section} id={id}>
    {onlyOne ? <h1 className={classes.title}>{title}</h1> : <h2 className={classes.title}>{title}</h2>}
    {children}
  </section>
)

export default Section
